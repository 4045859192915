import React, { Suspense, lazy, useState } from 'react';
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-01";
// import Footer from "@layout/footer/layout-01";
import PageHeader from "@containers/page-header/layout-01";
import FeatureGrid from "@components/feature-grid";
import IntroArea from "@components/introarea/layout-2";
import IntroAreaSecond from "@components/introarea/layout-1";
import BoxSection from "@components/BoxSection/layout-three/layout-3";
import BoxSectionSecond from "@components/BoxSection/layout-one/layout-4";
import BoxSectionThree from "@components/BoxSection/layout-three/layout-1";
import BoxSectionFour from "@components/BoxSection/layout-three/layout-1";
import CaseStudy from "@containers/global/case-study/layout-01";
import IntroAreaReverse from "@components/introareareverse/layout-1";
import FaqArea from "@containers/faq/layout-03";
import ContactArea from "@containers/contact-us";
import StickyMenu from "@containers/sticky-menu";
import { caseStudiesData } from "@constants";
import QuoteForm from "@containers/translation-quote-form";
import UseScroll from "@containers/scroll";
const Footer = lazy(() => import("@layout/footer/layout-01"))
const ProfessionalInterpretationServicesPage = ({ location, data }) => {
  const [showRestComponents, setShowRestComponents] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const content = normalizedData(data?.page.content || []);
  const globalContent = normalizedData(data?.allGeneral.nodes || []);
  UseScroll((scrollTop) => {
    if (scrollTop > 30) {
      setShowRestComponents(true);
    } else {
      setShowRestComponents(false);
    }
  }, setIsMobile);
  return (
    <Layout location={location}>
      <Seo
        title="Professional Interpretation Services by Andovar"
        description="Professional Interpretation Services"
        />
      <Header
        data={{
          ...globalContent["menu"],
          ...data.site.siteMetadata,
          isMobile: isMobile,
        }}
      />
      {
        !isMobile && <StickyMenu data={content["stycky-menu-data"]} setShowRestComponents={setShowRestComponents} showRestComponents={showRestComponents} />
      }
      <main className="site-wrapper-reveal">
        <PageHeader data={content["professional-interpretation-page-header"]} />
        <QuoteForm />
        {
          (!isMobile || (isMobile && showRestComponents)) && <>
           <FeatureGrid data={content["professional-interpretation-features"]} />
           <IntroArea layout={1} data={content["professional-interpretation-intro"]} />
           <BoxSection layout={1} data={content["professional-interpretation-solutions-section"]} />
          </>
        }
        {
          showRestComponents && <>
        <IntroAreaSecond layout={1} data={content["professional-interpretation-technology"]} />
        <BoxSectionSecond layout={5} data={content["professional-interpretation-expertise-section"]} />
        <IntroAreaReverse layout={1} data={content["professional-interpretation-services-section"]} />
        <BoxSectionThree layout={1} data={content["professional-interpretation-industries-we-serve-section"]} />
        <BoxSectionFour layout={4} data={content["professional-interpretation-contact-section"]} />
        <FaqArea data={content["professional-interpretation-faq-section"]} />
        <CaseStudy data={caseStudiesData} />
        <ContactArea Layout={2} />
        </>
        }
      </main>
      {
        showRestComponents && <Suspense fallback={<div></div>}>
          <Footer data={{ ...data.site.siteMetadata }} />
        </Suspense>
      }
    </Layout>
  );
};

export const query = graphql`
  query ProfessionalInterpretationServicesPageQuery {
    allGeneral {
      nodes {
        section
        ...HeaderOne
      }
    }
    site {
      ...Site
    }
    page(title: { eq: "professional-interpretation" }, pageType: { eq: "innerpage" }) {
      content {
        ...PageContent
      }
    }
  }
`;

ProfessionalInterpretationServicesPage.propTypes = {
  location: PropTypes.shape({}),
  data: PropTypes.shape({
    allGeneral: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        contact: PropTypes.shape({}),
      }),
    }),
    page: PropTypes.shape({
      content: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
};

export default ProfessionalInterpretationServicesPage;
